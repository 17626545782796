import React from 'react';
import { motion } from 'framer-motion';
import image1 from "../assets/knowledge.png";
import image2 from "../assets/cost.png";
import image3 from "../assets/adaptable.png";
import image4 from "../assets/courses.png";

const containerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 100,
            damping: 20,
            when: "beforeChildren",
            staggerChildren: 0.4,
        },
    },
};

const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
};

function Featuring() {
    return (
        <motion.section
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-700 sm:text-4xl md:mx-auto">
                        Transform Your Career Path with Edumitram
                    </h2>
                    <motion.p
                        className="text-gray-700 md:text-md text-sm"
                        variants={itemVariants}
                    >
                        Unlock access to an extensive library of courses, Specializations, and Professional Courses taught by top educators from prestigious institutions, companies, and K-12 schools.
                    </motion.p>
                </div>
                <div className="grid gap-8 row-gap-10 lg:grid-cols-2">
                    {[{
                        img: image1,
                        title: 'Expand Your Knowledge',
                        description: 'Delve into your passions or the latest trends, meet prerequisites, and grow your expertise.',
                    }, {
                        img: image2,
                        title: 'Cost-Effective Education',
                        description: 'Optimize your learning expenses by enrolling in multiple courses affordably.',
                    }, {
                        img: image3,
                        title: 'Adaptable Learning',
                        description: 'Learn at your own rhythm, seamlessly switch between courses, or explore new subjects anytime.',
                    }, {
                        img: image4,
                        title: 'Multiple Courses',
                        description: 'Also receive a certificate for each completed program at no additional charge.',
                    }].map((item, index) => (
                        <motion.div
                            key={index}
                            className="max-w-md sm:mx-auto sm:text-center"
                            variants={itemVariants}
                        >
                            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                                <motion.img
                                    className="w-12 h-12 sm:w-16 sm:h-16"
                                    src={item.img}
                                    alt={item.title}
                                    whileHover={{ scale: 1.1 }}
                                />
                            </div>
                            <h6 className="mb-3 text-xl font-bold leading-5">{item.title}</h6>
                            <p className="mb-3 text-sm text-gray-900">
                                {item.description}
                            </p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </motion.section>
    );
}

export default Featuring;
