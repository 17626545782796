import React, { useState, useEffect } from "react";
import CategoriesBanner from "../CategoriesBanner";
import Navbar from "../Navbar";
import { json, Link, useNavigate } from "react-router-dom";
import AssignedCourse from "./AssignedCourse";
import { CgProfile } from "react-icons/cg";
import ProfileDetails from "./ProfileDetails";
import config from "../../config/config.json";

const Profile = () => {
  const { WEB_BASE_URL } = config;

  const [course, setCourse] = useState(false);
  const navigate = useNavigate();
  const [courseLength, setcourseLength] = useState(0);
  const getCourseLength = (v) => {
    setcourseLength(v);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const gotoCourse = () => {
    const userSession = JSON.parse(localStorage.getItem("user"));
    if (userSession && userSession.token) {
      const token = userSession.token;
      const url = `${WEB_BASE_URL}?token=${token}`;
      window.open(url, "_blank");
    } else {
      console.error("issue of go to learn dashboard");
    }
  };

  const toggleMenu = (isProfile) => {
    setCourse(isProfile);
  };

  console.log(courseLength);

  return (
    <>
      {/* <CategoriesBanner /> */}
      <Navbar />
      {/* <buton data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button> */}

      <div className="w-full grid grid-cols-12 md:px-16 sm:px-32 lg:px-2 ">
        <div className="col-span-12 md:col-span-3 w-full max-md:max-w-md max-md:mx-auto ">
          <aside
            id="default-sidebar"
            className="fixed mt-2 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
            aria-label="Sidebar"
          >
            <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
              <ul className="space-y-2 font-medium">
                <li>
                  <a
                    href="#"
                    onClick={() => toggleMenu(false)}
                    className=" flex items-center p-2  gap-3 text-gray-900  rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group "
                  >
                    <CgProfile size={"1.4em"} />
                    <span className="flex-1">Profile</span>
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    onClick={() => toggleMenu(true)}
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                    >
                      {courseLength}
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          fill="none"
                          stroke="#494C4E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          d="M1 2h16v11H1z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#494C4E"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          d="M4 5.5v5s3-1 5 0v-5s-2-2-5 0zM9 5.5v5s3-1 5 0v-5s-2-2-5 0z"
                        ></path>{" "}
                        <path
                          fill="#494C4E"
                          stroke="#494C4E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          d="M8.5 14l-3 3h7l-3-3z"
                        ></path>{" "}
                      </g>
                    </svg>
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Course
                    </span>
                    {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                      {courseLength}
                    </span> */}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={gotoCourse}
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                    </svg>
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Go to Learn Dashboard
                    </span>
                  </a>
                </li>
                <li>
                  <Link
                    to="/forgot-user-password"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                      />
                    </svg>

                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Change Password
                    </span>
                  </Link>
                </li>
                <li>
                  <button
                    onClick={logout}
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                      />
                    </svg>
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      Logout
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </aside>
        </div>
        <div className="col-span-12 md:col-span-9 ">
          {!course ? (
            <ProfileDetails />
          ) : (
            <AssignedCourse setcourseLength={getCourseLength} />
          )}

          <div></div>
        </div>
      </div>
    </>
  );
};

export default Profile;
