import React from 'react';
import PropTypes from 'prop-types';

export const TextInput = ({ label, value, onChange, placeholder, required, error }) => (
    <div className="mt-4 w-full">
        <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
        <input
            className={`bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none placeholder-italic ${error ? 'border-red-500' : ''}`}
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
        />
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
);

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
};

export const EmailInput = ({ label, value, onChange, placeholder, required, error }) => (
    <div className="mt-4 w-full">
        <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
        <input
            className={`bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none placeholder-italic ${error ? 'border-red-500' : ''}`}
            type="email"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
        />
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
);

EmailInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
};

export const PasswordInput = ({ label, value, onChange, placeholder, required, error }) => (
    <div className="mt-4 w-full">
        <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
        <input
            className={`bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none placeholder-italic ${error ? 'border-red-500' : ''}`}
            type="password"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
        />
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
);

PasswordInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
};

export const NumberInput = ({ label, value, onChange, placeholder, required, error }) => (
    <div className="mt-4 w-full">
        <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
        <input
            className={`bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none placeholder-italic ${error ? 'border-red-500' : ''}`}
            type="number"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
        />
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
);

NumberInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
};

export const SelectInput = ({ label, value, onChange, options, required, error }) => (
    <div className="mt-4 w-full">
        <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
        <div className="relative">
            <select
                className={`bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none placeholder-italic pr-10 ${error ? 'border-red-500' : ''}`}
                value={value}
                onChange={onChange}
                required={required}
            >
                <option value="" disabled>Select your role</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                </svg>
            </div>
        </div>
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
);

SelectInput.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
};
