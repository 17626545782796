/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from "../assets/eduMitram.png"
import { CgProfile } from "react-icons/cg";


function Navbar() {
    const location = useLocation();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isServicesDropdown, setIsServicesDropdown] = useState(false);
    const [isSerDropdown, setIsSerDropdown] = useState(false);

    const userSession = localStorage.getItem('user');

    const isLinkActive = (pathname) => {
        return location.pathname === pathname;
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const mobileMenuRef = useRef(null);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleServicesDropdown = () => {
        setIsServicesDropdown(!isServicesDropdown);
        if (!isServicesDropdown) {
            setIsSerDropdown(false);
        }
    };

    const toggleSerDropdown = () => {
        setIsSerDropdown(!isSerDropdown);
        if (!isSerDropdown) {
            setIsServicesDropdown(false);
        }
    };

    const closeDropDowns = () => {
        setIsServicesDropdown(false);
        setIsSerDropdown(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                mobileMenuRef.current &&
                !mobileMenuRef.current.contains?.(event.target)
            ) {
                closeDropDowns();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    return (
        <header className="top-0 sticky z-50">
            <nav className="bg-[#FFF] border-b h-auto relative shadow-md shadow-[#a2a2a2]/40">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-[16px] px-2">
                    <Link to={`/`} className="flex items-center">
                        <img
                            src={logo}
                            className="w-40 mr-3"
                            alt="Educomp"
                        />
                    </Link>
                    <button
                        onClick={toggleMobileMenu}
                        data-collapse-toggle="navbar-default"
                        type="button"
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200"
                        aria-controls="navbar-default"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    </button>
                    <div
                        ref={mobileMenuRef}
                        className={`${isMobileMenuOpen ? "block" : "hidden"
                            } w-full md:block md:w-auto`}
                        id="navbar-default"
                    >
                        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-10 md:mt-0 md:border-0 text-sm">
                            <li className={`${isLinkActive('/') ? 'font-bold text-[] underline-offset-2' : ''}`}>
                                <Link
                                    onClick={scrollToTop}
                                    to={`/home`}
                                    className="block py-2 pl-3 pr-4  hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-[] md:p-0  md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded"
                                    aria-current="page"
                                >
                                    Home
                                </Link>
                            </li>
                            <hr className='md:hidden lg:hidden xl:hidden block py-2' />

                            <li className={`${isLinkActive('/instructors-browse-list-details') ? 'font-bold underline text-[rgb(43,175,43)] underline-offset-2' : ''}`}>
                                <Link
                                    onClick={scrollToTop}
                                    to={`/instructors-browse-list-details`}
                                    className="block py-2 pl-3 pr-4  hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-[rgb(43,175,43)] md:p-0  md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded"
                                >
                                    Instructors
                                </Link>
                            </li>

                            <hr className='md:hidden lg:hidden xl:hidden block py-2' />


                            <li className={`${isLinkActive('/user-courses') ? 'font-bold underline text-[rgb(43,175,43)] underline-offset-2' : ''}`}>
                                <Link
                                    onClick={scrollToTop}
                                    to={`/user-courses`}
                                    className="block py-2 pl-3 pr-4  hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-[rgb(43,175,43)] md:p-0  md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded"
                                >
                                    Courses
                                </Link>
                            </li>

                            <hr className='md:hidden lg:hidden xl:hidden block py-2' />

                            <li className=''>
                                {/* <Link
                                    href=""
                                    className={`gap-2 items-center py-2 pl-3 pr-4  flex text-gray-700  hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-[rgb(43,175,43)] md:p-0  md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded ${isSerDropdown ? "text-[#2baf2b]" : ""
                                        }`}
                                    onClick={toggleSerDropdown}
                                >
                                    Categories
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-4 h-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                        />
                                    </svg>
                                </Link> */}

                                {/* Small screen  */}
                                {isSerDropdown && (
                                    <div className="absolute mt-2 ml-2 bg-white border border-gray-200 rounded-lg shadow-md md:hidden lg:hidden xl:hidden 2xl:hidden w-full max-w-[200px]">
                                        <ul className="py-1">
                                            <li>
                                                <Link
                                                    onClick={scrollToTop}
                                                    to={`/investors/annual-results`}
                                                    className="block px-4 py-2  text-gray-700 hover:bg-gray-100"
                                                >
                                                    Development
                                                </Link>
                                            </li>
                                            <hr className='py-1 mx-4' />

                                            <li>
                                                <Link
                                                    onClick={scrollToTop}
                                                    to={`/investors/annual-reports`}
                                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                >
                                                    Business
                                                </Link>
                                            </li>
                                            <hr className='py-1 mx-4' />

                                            <li>
                                                <Link
                                                    onClick={scrollToTop}
                                                    to={`/investors/shareholding-patterns`}
                                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                >
                                                    IT/Software
                                                </Link>
                                            </li>
                                            <hr className='py-1 mx-4' />

                                            <li>
                                                <Link
                                                    onClick={scrollToTop}
                                                    to={`/investors/shareholding-patterns`}
                                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                >
                                                    Marketing
                                                </Link>
                                            </li>
                                            <hr className='py-1 mx-4' />

                                            <li>
                                                <Link
                                                    onClick={scrollToTop}
                                                    to={`/investors/shareholding-patterns`}
                                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                >
                                                    Music
                                                </Link>
                                            </li>
                                            {/* <hr className='py-1 mx-4' /> */}
                                        </ul>
                                    </div>
                                )}

                                {/* Larger screen  */}
                                {isSerDropdown && (
                                    <div className="md:flex lg:flex xl:flex 2xl:flex hidden absolute top-4 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 rounded-sm shadow-md max-w-5xl mt-16 px-2 py-2 w-full">
                                        <ul className="py-2 space-y-1 w-full max-w-lg">
                                            <li className="flex gap-3 hover:bg-[#2baf2b]/20 rounded-lg">
                                                <div>
                                                    <Link
                                                        onClick={scrollToTop}
                                                        to={`/investors/annual-results`}
                                                        className="block px-4 py-2 text-gray-700 space-y-2"
                                                    >
                                                        <div className='space-x-10 flex items-center'>

                                                            <p className="text-sm font-normal tracking-[2px] ">
                                                                Development
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </li>
                                            <hr className='mx-4' />
                                            <li className="flex gap-3 hover:bg-[#2baf2b]/20 rounded-lg">
                                                <div>
                                                    <Link
                                                        onClick={scrollToTop}
                                                        to={`/investors/annual-reports`}
                                                        className="block px-4 py-2 text-gray-700 space-y-2"
                                                    >
                                                        <div className='space-x-10 flex items-center'>
                                                            <p className="text-sm font-normal tracking-[2px] ">
                                                                Business
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </li>
                                            <hr className='mx-4' />
                                            <li className="flex gap-3 hover:bg-[#2baf2b]/20 rounded-lg">
                                                <div>
                                                    <Link
                                                        onClick={scrollToTop}
                                                        to={`/investors/shareholding-patterns`}
                                                        className="block px-4 py-2 text-gray-700 space-y-2"
                                                    >
                                                        <div className='space-x-10 flex items-center'>
                                                            <p className="text-sm font-normal tracking-[2px] ">
                                                                IT/Software
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </li>
                                            <hr className='mx-4' />
                                            <li className="flex gap-3 hover:bg-[#2baf2b]/20 rounded-lg">
                                                <div>
                                                    <Link
                                                        onClick={scrollToTop}
                                                        to={`/investors/corporate-governance-reports`}
                                                        className="block px-4 py-2 text-gray-700 space-y-2"
                                                    >
                                                        <div className='space-x-10 flex items-center'>
                                                            <p className="text-sm font-normal tracking-[2px] ">
                                                                Marketing
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </li>
                                            <hr className='mx-4' />
                                            <li className="flex gap-3 hover:bg-[#2baf2b]/20 rounded-lg">
                                                <div>
                                                    <Link
                                                        onClick={scrollToTop}
                                                        to={`/announce-corporate/board-meetings`}
                                                        className="block px-4 py-2 text-gray-700 space-y-2"
                                                    >

                                                        <div className='space-x-10 flex items-center'>

                                                            <p className="text-sm font-normal tracking-[2px] ">
                                                                Music
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="py-2 ml-4 space-y-4 border-l-2">
                                            {/* <li>
                                                <div className="py-4 px-2 w-full">
                                                    <video
                                                        className=" brightness-75 rounded-lg mx-8 max-w-2xl bg-blue-600"
                                                        autoPlay
                                                        muted
                                                        loop
                                                        playsInline
                                                    >
                                                        <source src={``} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </li> */}
                                            <li className="py-2 mx-2 bg-gray-200/70">
                                                <Link

                                                    href="/advancements"
                                                    className="block px-4 py-2 "
                                                >
                                                    <p className='text-gray-600 font-bold'>
                                                        Specially crafted solutions that seamlessly align
                                                        with your goals, exemplifying excellence in every
                                                        facet of our services.
                                                    </p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </li>

                            <hr className='md:hidden lg:hidden xl:hidden block py-2' />

                            {/* <li className={`${isLinkActive('/login-user') ? 'font-bold underline text-[] underline-offset-2' : ''}`}> */}
                            {/* <li className={`${isLinkActive('/login-users-pf-ds-bd') ? 'font-bold underline text-[] underline-offset-2' : ''}`}>
                                <Link
                                    onClick={scrollToTop}
                                    to={`/login-users-pf-ds-bd`}
                                    className="block py-2 pl-3 pr-4 hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-[] md:p-0 md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded"
                                >
                                    <span className='font-bold text-blue-500'>Login</span>
                                </Link>
                            </li> */}

                            {userSession ? 
                            <li className={`${isLinkActive('/user-profile') ? 'font-bold underline text-[] underline-offset-2' : ''}`}>
                                <Link
                                    onClick={scrollToTop}
                                    to={`/user-profile`}
                                >
                                    
                                        <CgProfile size={'1.8em'} />
                                    
                                    
                                </Link>
                            </li> : 
                            <li className={`${isLinkActive('/login-users-pf-ds-bd') ? 'font-bold underline text-[] underline-offset-2' : ''}`}>
                                <Link
                                    onClick={scrollToTop}
                                    to={`/login-users-pf-ds-bd`}
                                    className="block py-2 pl-3 pr-4 hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-[] md:p-0 md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded"
                                >
                                    <span className='font-bold text-blue-500'>Login</span>
                                </Link>
                            </li>}

                            <hr className='md:hidden lg:hidden xl:hidden block py-2' />

                            {/* <li className={`${isLinkActive('/singup-user') ? 'font-bold underline text-[] underline-offset-2' : ''}`}> */}
                            <li className={`${isLinkActive('/registration-users-pf-ds-bd') ? 'font-bold underline text-[] underline-offset-2' : ''}`}>
                                <Link
                                    onClick={scrollToTop}
                                    to={`/registration-users-pf-ds-bd`}
                                    className="block py-2 pl-3 pr-4  hover:bg-gray-200 md:hover:bg-transparent md:border-0 md:hover:text-[] md:p-0 md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded"
                                >
                                    {/* <span className='py-2 px-4 font-bold bg-gray-100 border border-blue-500 rounded-md text-blue-500'>Join for free</span> */}
                                </Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar




// {/* <li>
// <Link
//     href=""
//     className={`gap-2 items-center py-2 pl-3 pr-4  flex text-gray-700  hover:bg-gray-200md:hover:bg-transparent md:border-0 md:hover:text-[rgb(43,175,43)] md:p-0  md:font-medium tracking-normal md:hover:transition md:hover:duration-1000 rounded ${isServicesDropdown ? "text-[#2baf2b]" : ""
//         }`}
//     onClick={toggleServicesDropdown}
// >
//     Services
//     <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth="1.5"
//         stroke="currentColor"
//         className="w-5 h-5"
//     >
//         <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M19.5 8.25l-7.5 7.5-7.5-7.5"
//         />
//     </svg>
// </Link>

// {/* Small screen  */}
// {isServicesDropdown && (
//     <div className="absolute mt-2 ml-2 bg-white border border-gray-200 rounded-lg shadow-md md:hidden lg:hidden xl:hidden 2xl:hidden">
//         <ul className="py-2">
//             <li>
//                 <Link
//                     onClick={scrollToTop}
//                     to={`/solutions/b2b-page`}
//                     className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
//                 >
//                     B2B - Schools
//                 </Link>
//             </li>
//             <hr className='py-2 mx-4' />

//             <li>
//                 <Link
//                     onClick={scrollToTop}
//                     to={`/solutions/b2g-page`}
//                     className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
//                 >
//                     B2G - Government
//                 </Link>
//             </li>
//             <hr className='py-2 mx-4' />

//             <li>
//                 <Link
//                     onClick={scrollToTop}
//                     href="#"
//                     className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
//                 >
//                     B2C - Coming Soon
//                 </Link>
//             </li>
//         </ul>
//     </div>
// )}

// {/* Larger screen  */}
// {isServicesDropdown && (
//     <div className="md:flex lg:flex xl:flex 2xl:flex hidden absolute top-4 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 rounded-sm shadow-md max-w-2xl mt-12 px-2 py-2 w-full">
//         <ul className="py-2 space-y-4 w-full max-w-2xl">
//             <li className="flex gap-3 ">
//                 <img
//                     className="w-[170px] ml-2 rounded-md"
//                     src={``}
//                     alt="b2b-solutions"
//                 />
//                 <div>
//                     <Link
//                         onClick={scrollToTop}
//                         to={`/solutions/b2b-page`}
//                         className="block px-4 py-2 text-gray-700 hover:bg-[#2baf2b]/20 space-y-2"
//                     >
//                         <span className="text-sm font-extrabold tracking-[2px]">
//                             B2B - Schools & Institutions
//                         </span>
//                         <p className="text-sm">
//                             Students can use summarizing materials like subject summaries and concept maps to support their learning.
//                         </p>
//                         <div className="font-bold text-[#2baf2b] text-sm underline underline-offset-2 flex justify-end items-center">
//                             <p>Read More..</p>

//                         </div>
//                     </Link>
//                 </div>
//             </li>

//             <li className="flex gap-3 ">
//                 <img
//                     className="w-[170px] ml-2 rounded-md cover"
//                     src={``}
//                     alt="b2g-solutions"
//                 />
//                 <div>
//                     <Link
//                         onClick={scrollToTop}
//                         to={`/solutions/b2g-page`}
//                         className="block px-4 py-2 text-gray-700 hover:bg-[#2baf2b]/20 space-y-2"
//                     >
//                         <span className="text-sm font-extrabold tracking-[2px] ">
//                             B2G - For Government
//                         </span>

//                         <p className="text-sm">
//                             The kids will be taught in accordance with the curriculum established by the school board.
//                         </p>
//                         <div className="font-bold text-[#2baf2b] text-sm underline underline-offset-2 flex justify-end items-center ">
//                             <p>Read More..</p>

//                         </div>
//                     </Link>
//                 </div>
//             </li>

//             <li className="flex gap-3 ">
//                 <img
//                     className="w-[170px] ml-2 rounded-md cover"
//                     src={``}
//                     alt="b2c-solutions"
//                 />
//                 <div>
//                     <Link
//                         onClick={scrollToTop}
//                         to={`#`}
//                         className="block px-4 py-2 text-gray-700 hover:bg-[#2baf2b]/20 space-y-2"
//                     >
//                         <span className="text-sm font-extrabold tracking-[2px] ">
//                             B2C - Online Applications
//                         </span>

//                         <p className="text-sm">
//                             Coming soon                                                        </p>
//                         {/* <div className="font-bold text-[#2baf2b] text-sm underline underline-offset-2 flex justify-end items-center ">
//                             <p>Read More..</p>

//                         </div> */}
//                     </Link>
//                 </div>
//             </li>

//         </ul>

//     </div>
// )}
// </li> */}

