import React, { useEffect, useState } from "react";
import config from "../../config/config.json";
import axios from "axios";

const ProfileDetails = () => {
  const { API_BASE_URL } = config;
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem("user"));
    if (userSession && userSession.uuid) {
      const userId = userSession.result.userID;
      if (userId) {
        getAssignCourse(userId);
      }
      // setUserId(userId);
    } else {
      console.error("localstorage data");
    }
  }, []);
  const getAssignCourse = async (userId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/User/GetStudentDetailsByID`,
        {
          UserID: userId,
          ID: -1,
        }
      );
      console.log(response.data[0]);
      setUserProfile(response.data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="mx-auto max-w-3xl">
      <h1 className="text-3xl font-bold pt-5 pb-5">Profile</h1>

      <form className="mx-auto p-4 grid grid-cols-1 md:grid-cols-2 justify-stretch items-center gap-4 ">

        <label for="fname"
            className="block mb-2 text-sm font-medium text-gray-900 "
            >
            First Name
            <input type="text" id="fname"
            className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.FirstName}/>
        </label>
        <label for="lname"
            className="block mb-2 text-sm font-medium text-gray-900 "
            >
            Last Name
            <input type="text" id="lname"
            className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.LastName}/>
        </label>
        <label for="email"
            className="block mb-2 text-sm font-medium text-gray-900 "
            >
            Email
            <input type="email" id="email"
            className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.EmailId}/>
        </label>
        <label for="mobile"
            className="block mb-2 text-sm font-medium text-gray-900 "
            >
            Mobile
            <input type="number" id="mobile"
            className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.MobileNumber}/>
        </label>
        {/* <div>
          <label
            for="email"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            {" "}
            First Name
          </label>
          <input
            type="text"
            id="email"
            className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.FirstName}
          />
        </div>
        <div className="mb-5 w-2/3">
          <label
            for="password"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Last Name
          </label>
          <input
            type="text"
            id="password"
            className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.LastName}
          />
        </div>

        <div className="mb-5 w-2/3">
          <label
            for="password"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Email
          </label>
          <input
            type="email"
            id="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.EmailId}
          />
        </div>
        <div className="mb-5 w-2/3">
          <label
            for="password"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Mobile
          </label>
          <input
            type="number"
            id="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={userProfile?.MobileNumber}
          />
        </div> */}
      </form>
    </div>
  );
};

export default ProfileDetails;
