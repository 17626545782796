import React, { useEffect, useState } from "react";
import { SidebarWithBurgerMenu } from "./CourseFillterMenu";
import { Link } from "react-router-dom";
import config from "../../../config/config.json";
import CartButton from "../../CartButton";

export const AllCourses = () => {
  const { API_BASE_URL } = config;
  const [currentPage, setCurrentPage] = useState(1);
  const [AllCourses, setAllCourse] = useState(null);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(AllCourses?.length / itemsPerPage);

  useEffect(() => {
    const fetchAllCourse = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/Courses/GetAllCourses`, {
          method: "GET",
        });

        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          setAllCourse(data);
        } else {
          console.error("Error fetching course details");
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchAllCourse();
  }, [API_BASE_URL]);

  const scrollDownToSection = () => {
    const section = document.getElementById("booklist");
    section?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="bg-[#F3F4F6] min-h-screen p-2 rounded-md">
        <div className="lg:-space-x-14 md:space-x-2 sm:space-x-30">
          <div className="max-w-7xl mx-auto">
            <div className="grid md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3 gap-5 space-y-4 md:space-y-0">
              {AllCourses?.map((course, index) => (
                <Link
                  to={`/user-courseDetails/${course.courseId}`}
                  className="max-w-sm bg-white px-3 pt-1 pb-1 rounded-xl shadow-lg transform hover:scale-105 transition duration-500"
                  key={index}
                >
                  <h3 className="mb-2 text-xl font-bold text-indigo-600">
                    {course.level}
                  </h3>
                  <div className="relative">
                    <img
                      src={`${API_BASE_URL}/${course.courseThumbnail}`}
                      alt=""
                      className="w-full h-48 object-cover rounded-xl"
                    />
                    <p className="absolute top-0 bg-yellow-300 text-gray-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
                      {/* {course.isFree ? "FREE" : `₹${course?.pricing}`} */}
                    </p>
                  </div>
                  <p className="mt-4 text-gray-800 text-xl font-bold cursor-pointer">
                    {course?.courseName}
                    
                  </p>
                  <p className="mt-2 text-gray-600  line-clamp-2">
                    {course?.userName}
                  </p>
                  <div className="my-4">
                    <div className="flex space-x-1 items-center">
                      <span className="font-bold text-indigo-600">Rating:</span>
                      {/* <p>{course.totalRating}</p> */}
                      {Array.from({ length: 5 }, (_, index) => (
                                <svg
                                    key={index}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-4 text-yellow-500"
                                >
                                    {index < course.totalRating ? (
                                        <path
                                            fillRule="evenodd"
                                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                            clipRule="evenodd"
                                        />
                                    ) : (
                                        <path
                                            fillRule="evenodd"
                                            d="M12 2c.674 0 1.316.337 1.682.909l2.083 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005c.366-.572 1.008-.909 1.682-.909Zm0 2.23l-1.733 4.166c-.183.441-.609.742-1.098.78l-4.393.353 3.323 2.847c.354.303.514.785.402 1.24l-1.014 4.249 3.573-2.181c.414-.253.938-.253 1.352 0l3.573 2.181-1.014-4.249c-.112-.455.048-.937.402-1.24l3.323-2.847-4.393-.353c-.49-.038-.915-.339-1.098-.78L12 4.23Z"
                                            clipRule="evenodd"
                                        />
                                    )}
                                </svg>
                            ))}

                    </div>

                    <button className="mt-4 text-xl w-full text-white bg-indigo-600 py-2 rounded-xl shadow-lg">
                      {/* {course.isFree ? "Enroll Now" : "Add to Cart"} */}
                      View Details
                    </button>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <button
            onClick={() => {
              setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
              scrollDownToSection();
            }}
            disabled={currentPage === 1}
            className="px-3 py-2 mr-2 font-semibold text-gray-700 bg-gray-200 rounded"
          >
            Previous
          </button>
          <span className="px-3 py-2 font-semibold text-gray-700">
            Page {currentPage} of {totalPages || 0}
          </span>
          <button
            onClick={() => {
              setCurrentPage((prevPage) => prevPage + 1);
              scrollDownToSection();
            }}
            disabled={currentPage * itemsPerPage >= AllCourses?.length}
            className="px-3 py-2 ml-2 font-semibold text-white bg-blue-500 rounded"
          >
            Next
          </button>
        </div>
      </div>

      {/* <CartButton /> */}
    </>
  );
};

export default AllCourses;

// import React, { useEffect, useState } from 'react'
// import img1 from '../../../assets/images/img-1.jpg'
// import { SidebarWithBurgerMenu } from './CourseFillterMenu'
// import CategoriesBanner from '../../../components/CategoriesBanner'
// import Navbar from '../../../components/Navbar'
// import { Link } from 'react-router-dom'
// import config from '../../../config/config.json';

// export const AllCourses = () => {
//     const { API_BASE_URL } = config;
//     const [currentPage, setCurrentPage] = useState(1);
//     const [AllCourses, setAllCourse] = useState(null);
//     const itemsPerPage = 8;
//     const totalPages = Math.ceil(AllCourses?.length / itemsPerPage);

//     useEffect(() => {
//         const fetchAllCourse = async () => {
//             try {
//                 const response = await fetch(`${API_BASE_URL}/Courses/GetAllCourses`, {
//                     method: 'GET',
//                 });

//                 if (response.ok) {
//                     const data = await response.json();
//                     console.log("data", data);
//                     setAllCourse(data);
//                 } else {
//                     console.error('Error fetching course details');
//                 }
//             } catch (error) {
//                 console.error('Error fetching course details:', error);
//             } finally {
//                 console.log("finally block");
//             }
//         };

//         fetchAllCourse();
//     }, []);

//     return (
//         <>
            // <CategoriesBanner />
//             <Navbar />
//             <div className='flex bg-[#F3F4F6] min-h-screen'>
//                 <div className='w-1/5  top-0 left-0 h-full p-5'>
//                     <SidebarWithBurgerMenu />
//                 </div>
//                 <div className='w-4/5 ml-auto p-5'>
//                     <div className="grid md:grid-cols-3 grid-cols-2 lg:gap-4 gap-2">
//                         <Link to='/courseDetails' className="p-2 rounded-lg">
//                             <div className="relative">
//                                 <img src={img1} className="rounded-3xl w-80 h-full mx-5" />
//                             </div>
//                             <div className='bg-white w-[22.5rem] h-80 mt-24 rounded-3xl'>
//                                 <div className='p-5'>
//                                     <h1 className='text-blue-700 mt-28'>
//                                         <span className='bg-[#E5F6FD] px-2 pb-0.5 rounded-full font-semibold'>Beginner</span>
//                                     </h1>
//                                     <h1 className='font-medium text-gray-500 line-clamp-2 mb-2 mt-2 text-xl'>
//                                         Lorem ipsum dolor, sit amet consectetur adipisicing elit. Commodi, ratione mollitia excepturi harum culpa voluptatibus exercitationem inventore dolores? Explicabo corrupti fugiat ratione. Impedit laudantium illum itaque fugit animi amet expedita.
//                                     </h1>
//                                     <div className='flex space-x-2 items-center mb-2'>
//                                         <div className='flex items-center'>
//                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-yellow-500">
//                                                 <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
//                                             </svg>
//                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-yellow-500">
//                                                 <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
//                                             </svg>
//                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-yellow-500">
//                                                 <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
//                                             </svg>
//                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-yellow-500">
//                                                 <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
//                                             </svg>
//                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-yellow-500">
//                                                 <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
//                                             </svg>
//                                         </div>
//                                         <p className="text-gray-700 text-[18px]">(4.5/204 Ratings )</p>
//                                     </div>
//                                     <div className='flex justify-between'>
//                                         <p className="font-bold text-[#EF5B51] mt-1">₹999</p>
//                                         <button className='border border-red-500 pl-3 pr-3 pt-1 pb-1 bg-[#F14B16] rounded-md text-white'>Add to cart</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default AllCourses
