import React from "react";
import { Routes, Route } from 'react-router-dom';
import HeroPage from "./pages/HeroPage";
import InstitutePage from "./pages/InstitutePage";
// import Login from "./components/core/Forms/LoginForm";
// import { Singup } from "./components/core/Forms/Signup";
import { ForgotPassword } from "./components/core/Forms/ForgotPassword";

import ChangePassword from "./components/core/Forms/ChangePassword";


import IndividualPage from "./pages/IndividualPage";
import SchoolPage from "./pages/SchoolPage";
import BusinessPage from "./pages/BusinessPage";
// import UserRegistrationPage from "./pages/UserRegistrationPage";
// import LoginPage from "./pages/LoginPage";
import CourseDeatil from "./components/School/course/CourseDeatil";

import Login from "./components/Login";
import Signup from "./components/Signup";
import ProfilePage from "./pages/ProfilePage";
import Cart from './components/School/course/Cart'
import CourseFilter from "./components/School/course/CourseFillterMenu";
import InstructorPage from "./pages/InstructorPage";
import Profile from "./components/Profile/Profile";





function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<BusinessPage/>} />
        <Route path='/home' element={<HeroPage/>} />

        <Route path="/login-user" element={<Login/>}/>
        {/* <Route path="/singup-user" element={<Singup/>}/> */}
        <Route path="/forgot-user-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        {/* <Route path='/en-institutions-bacthes-courses' element={<InstitutePage />} /> */}



        {/* <Route path='/en-individual-selections-courses' element={<IndividualPage />} /> */}
        {/* <Route path='/en-schools-classes-courses' element={<SchoolPage />} /> */}
        {/* <Route path='/en-business-worldwide-courses' element={<BusinessPage />} /> */}
        <Route path='/user-courses' element={<CourseFilter />} />
        <Route path="user-courseDetails/:id" element={<CourseDeatil />} />

        {/* <Route path='/login-users-pf-ds-bd' element={<LoginPage />} />
        <Route path='/registration-users-pf-ds-bd' element={<UserRegistrationPage />} /> */}

        <Route path='/login-users-pf-ds-bd' element={<Login />} />
        {/* <Route path='/registration-users-pf-ds-bd' element={<Signup />} /> */}

        {/* Instrcutors */}
        <Route path='/instructors-browse-list-details' element={<InstructorPage />} />

        {/* Profile Page  */}
        <Route path='/profile-details-instructors' element={<ProfilePage />} />
        {/* <Route path='/user-carts' element={<Cart />} /> */}
        <Route path="/user-profile" element={<Profile/>}/>


      </Routes>
    </div>
  );
}

export default App;
