import React from 'react'
import CategoriesBanner from '../components/CategoriesBanner'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Featuring from '../components/Featuring'
import CourseCarousel from '../components/CourseCarousel'
import Footer from '../components/Footer'
import BusinessReviews from '../components/Business/BusinessReviews'
import AnimationText from '../components/AnimationText'

function HeroPage() {
    return (
        <div>
            {/* <CategoriesBanner /> */}
            <Navbar />
            <Hero />
            <Featuring />
            {/* <CourseCarousel /> */}
            <AnimationText
                text1="Unlock your potential with our tailored learning solutions designed for every student, teacher, and institution!"
                text2="Transform your educational journey with interactive courses, expert instructors etc." />
            {/* <BusinessReviews /> */}
            <Footer />
        </div>
    )
}

export default HeroPage