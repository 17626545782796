import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CategoriesBanner from './CategoriesBanner';
import Navbar from './Navbar';
import bgimage from "../assets/login.png";
import config from './config/config.json';
import { EmailInput, PasswordInput } from './Inputs';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateInputs = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required';
        if (!password) newErrors.password = 'Password is required';
        return newErrors;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const newErrors = validateInputs();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const payload = {
            Username: email,
            Password: password,
        };

        try {
            const response = await fetch(`${config.API_BASE_URL}/Users/DBLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const data = await response.json();

            if (data.isSuccess) {
                localStorage.setItem('user',JSON.stringify(data))

                navigate('/user-profile');
            } else {
                setErrorMessage(data.displayMessage || 'Login failed');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <section>
            {/* <CategoriesBanner /> */}
            <Navbar />
            <div className="py-10 ">
                <div className="flex bg-white rounded-sm overflow-hidden mx-auto max-w-sm lg:max-w-5xl">
                    <div className="hidden lg:block lg:w-1/2">
                        <img src={bgimage} alt="Descriptive Alt Text" className="w-full h-full object-cover" />
                    </div>
                    <div className="w-full p-8 lg:w-1/2">
                        <h2 className="text-2xl font-semibold text-gray-700 text-center">Educrafter</h2>
                        <p className="text-xl text-gray-600 text-center">Welcome!</p>
                        <div className="mt-4 flex items-center justify-between">
                            <span className="border-b w-1/5 lg:w-1/4"></span>
                            <Link to="#" className="text-xs text-center text-gray-500 uppercase">Login with email</Link>
                            <span className="border-b w-1/5 lg:w-1/4"></span>
                        </div>
                        <form onSubmit={handleLogin}>
                            <EmailInput
                                label="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="alicesmith@gmail.com"
                                required
                                error={errors.email}
                            />
                            <PasswordInput
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="**********"
                                required
                                error={errors.password}
                            />
                            <div className='mt-2 flex justify-end items-end'>
                                <Link to="/forgot-user-password">
                                    <p className='text-xs underline'>Forgot password?</p>
                                </Link>
                            </div>
                            {errorMessage && (
                                <div className="mt-2 text-red-500 text-sm text-center">
                                    {errorMessage}
                                </div>
                            )}
                            <div className="mt-4">
                                <button type="submit" className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">
                                    Login
                                </button>
                            </div>
                        </form>
                        {/* <div className="mt-4 flex items-center justify-between px-1">
                            <Link to="/registration-users-pf-ds-bd" className="text-sm text-gray-500">
                                Don't have an account? <span className='underline underline-offset-2 text-blue-500 font-medium'>Register now</span>
                            </Link>
                        </div> */}
                        <hr className='mt-4 border-b-4 border-gray-700' />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
