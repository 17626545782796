import React, { useEffect, useState } from "react";
import config from "../../config/config.json";
import axios from "axios";

const AssignedCourse = ({setcourseLength}) => {
  const { API_BASE_URL, WEB_BASE_URL } = config;
  const [AssignedCourse, setAssignedCourse] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem("user"));
    if (userSession && userSession.uuid) {
      const userId = userSession.uuid;
      if (userId) {
        getAssignCourse(userId);
      }
      // setUserId(userId);
    } else {
      console.error("localstorage data");
    }
  }, []);
  const getAssignCourse = async (userId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/Courses/GetAssignedCourses`,
        {
          userid: userId,
        }
      );
      console.log(response.data);
      setAssignedCourse(response.data);
      setcourseLength(response.data.length)
     
    } catch (error) {
        console.log("error", error);
    }
};

  const gotoCourse = () => {
    const userSession = JSON.parse(localStorage.getItem("user"));
    if (userSession && userSession.token) {
      const token = userSession.token;
      const url = `${WEB_BASE_URL}?token=${token}`;
      window.open(url, "_blank");
    } else {
      console.error("issue of go to learn dashboard");
    }
  };

  return (
    <>
      <h1 className="text-3xl pt-5 pb-5 font-bold">Course</h1>
      <div className="grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-3">
        {AssignedCourse?.map((item, index) => (
          <div
            
            class="relative flex justify-between items-end mb-16 overflow-hidden text-gray-900 flex-col  bg-white border border-gray-200 rounded-lg shadow  w-full hover:bg-gray-100 "
          >
            <img
              class="object-cover w-full rounded-t-lg aspect-square w- md:rounded-none md:rounded-s-lg opacity-40 "
              src={`${API_BASE_URL}/${item?.courseThumbnail}`}
              alt=""
            />

            <div class="group absolute bottom-0 text-gray-900  h-full grid justify-center items-center p-4 leading-normal bg-black/20 hover:bg-black/70 hover:duration-200">
              <h5
                class="mb-2 text-2xl font-bold tracking-tight group-hover:text-white    "
                key={index}
              >
                {item?.courseName}
              </h5>
              <p class="group-hover:text-lg group-hover:text-white mb-3 font-bold ">
                {item?.courseDescription}
              </p>
              <button
                onClick={gotoCourse}
                className="p-2 place-self-end rounded-xl  border-[1px] text-[1.4vw] font-bold hover:bg-blue-800 text-black group-hover:text-white transition"
              >
                Start Learning
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AssignedCourse;
