import React, { useState } from 'react';
import login_img from '../../../assets/login.gif'
import edumitramLogo from '../../../assets/eduMitram.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form'
import { Input } from '../../Input'
import { BsFillCheckSquareFill } from 'react-icons/bs'

import config from '../../../config/config.json';





import {
  confirm_password_validation,
  password_validation,
} from '../../../utils/inputValidations'

const ChangePassword = (props) => {
  const { API_BASE_URL } = config;

  const navigate = useNavigate();


  const methods = useForm()
  const [success, setSuccess] = useState(false);
  const [componentFalg, setcomponetFlag] = useState(true)
  const [errorMessage, setErrorMessage] = useState('');
  const [toEmail, setToEmail] = useState(null);
  const [otpSucess,setOtpSucess] = useState(false);
  const [otpErrorMesaage,setOtpErrorMesaage] = useState('')
  const [changepsswordflag,setchangepsswordflag]=useState(true);


  const onSubmit = methods.handleSubmit(async (data) => {


    console.log("email data", data);

    const email = data.ToEmail;
    setToEmail(email);

    console.log("setToEmail", email);

    try {
      const response = await fetch(`${API_BASE_URL}/User/ChangePassword`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({ NewPassword:data.Password,  ConfirmPassword:data.ConfirmPassword, ToEmail: props.ToEmail})
      })
      if (response.ok) {
        console.log("if block")
        let data = response.text();
        console.log("data", data)
        methods.reset();
        setSuccess(true);
        setcomponetFlag(false);
        setErrorMessage(''); // Clear any previous error message
        navigate('/login-user')



      }
      else {
        setSuccess(false);

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          let errorData = await response.json();
          setErrorMessage(errorData.message || 'Something went wrong');
        } else {
          let errorText = await response.text();
          setErrorMessage(errorText);
        }
      }
    } catch (error) {
      console.error("api error", error)
      setErrorMessage('Network error. Please try again later.');
    }
  })
  const onOtpSubmit = async (Otp) => {
    const OtpInt = parseInt(Otp, 10);

    console.log("Login Successful", OtpInt);
    try {
      const response = await fetch(`${API_BASE_URL}/User/ChangePassword`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({ Otp: OtpInt, ToEmail: toEmail })
      })
      if (response.ok) {
        console.log("if block")
        let data = response.text();
        console.log("data", data)
        methods.reset();
        setOtpSucess(true);
        setErrorMessage('');
         setchangepsswordflag(false);



      }
      else {
        setchangepsswordflag(false);

        setOtpSucess(false);

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          let errorData = await response.json();
          console.log("errorData", errorData)
          setErrorMessage(errorData.message || 'Something went wrong');
        } else {

          let errorText = await response.text();
          console.log("errorText", errorText)

          setOtpErrorMesaage(errorText);
        }
      }
    } catch (error) {
      console.error("api error", error)
      setErrorMessage('Network error. Please try again later.');
    }


  };

 
  return (
    <div className=' w-full flex justify-center items-center'>

       <div className=' w-full bg-white    rounded-md '>

    <FormProvider {...methods}>
    <form className="space-y-4 " onSubmit={e => e.preventDefault()}
      noValidate
      autoComplete="off">
                            {/* <img src={edumitramLogo} alt='edumitramLogo' className='w-full h-26 mb-6' /> */}
                            <Input {...password_validation} />
                            <Input {...confirm_password_validation} />

        <div className="mt-5">
        {success && (
          <p className="font-semibold text-green-500 mb-5 flex items-center gap-1">
            <BsFillCheckSquareFill /> Password has been change successfully
          </p>
        )}
         {errorMessage && (
          <p className="error text-red-600 text-[20px] mb-4"> {errorMessage} </p>
        )}
{/* 
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="remember_me"
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">Remebrer me</label>
        </div>
        <div className="text-sm">
          <Link to={`/forgot-user-password`} className="font-medium text-blue-600 hover:text-blue-500">Forgot Password</Link>
        </div>
      </div> */}
      <div>
        <button onClick={onSubmit} className="w-full py-2 mt-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ">
          Change Password
        </button>
        
      </div>
      </div>
    </form>
  </FormProvider>
  </div>
  </div>
  )
}

export default ChangePassword