import React from 'react'
import CategoriesBanner from '../components/CategoriesBanner'
import Navbar from '../components/Navbar'
import BusinessHero from '../components/Business/BusinessHero'
import BusinessAbourt from '../components/Business/BusinessAbourt'
import BusinessReviews from '../components/Business/BusinessReviews'
import Footer from '../components/Footer';
import Featuring from '../components/Featuring'
import CourseCarousel from '../components/CourseCarousel'

function BusinessPage() {
    return (
        <div>
            {/* <CategoriesBanner /> */}
            <Navbar />
            <BusinessHero />
            <BusinessAbourt />
            <Featuring />
            {/* <CourseCarousel /> */}

            {/* <BusinessReviews /> */}
            <Footer />
        </div>
    )
}

export default BusinessPage