import React from 'react'
import profileimage from "../../assets/profile.jpg"

function ProfileHero() {
    return (
        <div class="min-h-screen  flex flex-wrap">
            <div class=" bg-white shadow-lg  transform duration-200 easy-in-out">
                <div class=" h-48 overflow-hidden" >
                    <img class="w-full" src="https://images.unsplash.com/photo-1605379399642-870262d3d051?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80" alt="" />
                </div>
                <div class="flex justify-center px-5  -mt-12">
                    <img class="md:h-32 md:w-32 h-32 w-32 lg:w-40 lg:h-40 xl:h-40 xl:w-40 bg-white p-2 rounded-full" src={profileimage} alt="" />

                </div>
                <div class=" ">
                    <div class="text-center px-14">
                        <h2 class="text-gray-800 text-3xl font-bold">Mohit Dhiman</h2>
                        <a class="text-gray-400 mt-2 hover:text-blue-500" href="https://www.instagram.com/immohitdhiman/" target="BLANK()">@immohitdhiman</a>
                        <p class="mt-2 text-gray-500 md:text-md text-sm">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                    </div>
                    <hr class="mt-6 max-w-7xl mx-auto" />
                    <div class="flex bg-gray-100 max-w-5xl mx-auto">
                        <div class="text-center font-bold text-white tracking-[1px]  w-1/2 p-4 hover:bg-gray-100 bg-blue-600 cursor-pointer">
                            <p>Assistant Professor</p>
                        </div>
                        <div class="border"></div>
                        <div class="text-center w-1/2 p-4 hover:bg-gray-100 cursor-pointer">
                            <p> <span class="font-semibold">2.0 k </span> Following</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileHero