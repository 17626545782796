import React from 'react';
import { Link } from 'react-router-dom';
import './css/Footer.css';
import logo from "../assets/logo-white.png";
import facebook from "../assets/socials/facebook.png";
import linkedin from '../assets/socials/linkedin.png';
import youtube from "../assets/socials/youtube.png";
import instagram from "../assets/socials/instagram.png";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="footer-logo">
                            <Link to="/">
                                <img src={logo} alt="Edumitram Pvt Ltd" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-links">
                            <h3>Company</h3>
                            <ul>
                                <li>
                                    <Link to="">About Us</Link>
                                </li>
                                <li>
                                    <Link to="">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="">Careers</Link>
                                </li>
                                <li>
                                    <Link to="">Blog</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-links">
                            <h3>Support</h3>
                            <ul>
                                <li>
                                    <Link to="">Help and Support</Link>
                                </li>
                                {/* <li>
                                    <Link to="/affiliate">Affiliate</Link>
                                </li>
                                <li>
                                    <Link to="/investors">Investors</Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-links">
                            <h3>Legal</h3>
                            <ul>
                                <li>
                                    <Link to="">Terms</Link>
                                </li>
                                <li>
                                    <Link to="">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="">Cookie Settings</Link>
                                </li>
                                <li>
                                    <Link to="">Sitemap</Link>
                                </li>
                                <li>
                                    <Link to="">Accessibility Statement</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className='flex justify-end py-4 px-2'>
                    <button className='border border-gray-400 px-3 py-2'>Language</button>
                </div> */}
                <hr className='opacity-25' />
                <div className="row mt-6">
                    <div className="col-md-12 md:flex md:justify-between md:w-full md:items-center md:space-y-0 flex-none space-y-2">
                     
                        <div className="items-center flex gap-4">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="Facebook" className="h-6 w-6" />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt="Instagram" className="h-6 w-6" />
                            </a>
                            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                                <img src={youtube} alt="YouTube" className="h-6 w-6" />
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <img src={linkedin} alt="LinkedIn" className="h-6 w-6" />
                            </a>
                        </div>
                        <div className="footer-copyright">
                            <p className='text-sm text-gray-200'>
                                © 2024 Edumitram Pvt Ltd.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;