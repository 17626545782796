import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CategoriesBanner from './CategoriesBanner';
import Navbar from './Navbar';
import bgimage from "../assets/Signup.mp4";
import config from './config/config.json';
import { TextInput, EmailInput, PasswordInput, NumberInput, SelectInput } from './Inputs';
import { role_options } from './utils/inputValidations';

function Signup() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // const [selectedRole, setSelectedRole] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});

    const [selectedRole, setSelectedRole] = useState({ value: '', label: '' });

    const API_BASE_URL = config.API_BASE_URL;

    const handleRoleChange = (e) => {
        const selectedOption = role_options.options.find(option => option.value === parseInt(e.target.value));
        setSelectedRole({ value: selectedOption.value, label: selectedOption.label });
    }

    const validateInputs = () => {
        const newErrors = {};
        if (!firstName) newErrors.firstName = 'First name is required';
        if (!lastName) newErrors.lastName = 'Last name is required';
        if (!email) newErrors.email = 'Email is required';
        if (!mobileNumber) newErrors.mobileNumber = 'Mobile number is required';
        if (!password) newErrors.password = 'Password is required';
        if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
        if (!selectedRole) newErrors.selectedRole = 'Role is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateInputs();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const payload = {
            UserRoles: [selectedRole.label],
            roleid:selectedRole.value,
            Email: email,
            Password: password,
            ConfirmPassword: confirmPassword,
            FirstName: firstName,
            LastName: lastName,
            MobileNumber: mobileNumber,
            SchoolId:-1
        };

        try {
            const response = await fetch(`${API_BASE_URL}/Users/RegisterUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                console.log('User registered successfully!');
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.errors?.[0]?.message || 'Failed to register user');
            }
        } catch (error) {
            console.error('Error registering user:', error);
            setErrorMessage('Network error or API failure.');
        }
    };

    return (
        <section>
            {/* <CategoriesBanner /> */}
            <Navbar />
            <div className="py-6">
                <div className="flex bg-white rounded-sm overflow-hidden mx-auto max-w-sm lg:max-w-7xl">
                    <div className="hidden lg:block lg:w-1/2">
                        <video src={bgimage} autoPlay loop muted className="w-full h-full object-cover pointer-events-none"></video>
                    </div>
                    <div className="w-full p-8 lg:w-1/2">
                        <h2 className="text-3xl font-semibold text-gray-700 text-center">Educrafter</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mt-4 lg:flex xl:flex md:flex lg:gap-2 xl:gap-2 md:gap-2 flex-none w-full">
                                <TextInput
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="Alice"
                                    required
                                    error={errors.firstName}
                                />
                                <TextInput
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder="Smith"
                                    required
                                    error={errors.lastName}
                                />
                            </div>
                            <div className="mt-4 lg:flex xl:flex md:flex lg:gap-2 xl:gap-2 md:gap-2 flex-none w-full">
                                <EmailInput
                                    label="Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="alicesmith@gmail.com"
                                    required
                                    error={errors.email}
                                />
                                <NumberInput
                                    label="Mobile Number"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    placeholder="Your mobile number"
                                    required
                                    error={errors.mobileNumber}
                                />
                            </div>
                            <SelectInput
                                label="Role"
                                value={selectedRole.value}
                                onChange={handleRoleChange}
                                options={role_options.options}
                                required
                                error={errors.selectedRole}
                            />
                            <div className="mt-4 lg:flex xl:flex md:flex lg:gap-2 xl:gap-2 md:gap-2 flex-none w-full">
                                <PasswordInput
                                    label="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="**********"
                                    required
                                    error={errors.password}
                                />
                                <PasswordInput
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="**********"
                                    required
                                    error={errors.confirmPassword}
                                />
                            </div>

                            <div className="mt-8">
                                <button type="submit" className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">Register</button>
                            </div>

                            <div className="mt-4 flex items-center justify-between px-1">
                                <Link to="/login-users-pf-ds-bd" className="text-sm text-gray-500">Already have an account? <span className='underline underline-offset-2 text-blue-500 font-medium'>Login now</span></Link>
                            </div>
                        </form>
                        <hr className='mt-4 border-b-4 border-gray-700' />
                        {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Signup;
