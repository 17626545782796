import React from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion";

function ParallaxText({ children, baseVelocity = 100 }) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  // Move text horizontally from -100% to 100% for continuous effect
  const x = useTransform(baseX, (v) => `${v}%`);

  useAnimationFrame((t, delta) => {
    let moveBy = baseVelocity * (delta / 1000);
    if (velocityFactor.get() < 0) {
      moveBy *= -1;
    }
    baseX.set(baseX.get() + moveBy);

    // Reset position to create a continuous scroll
    if (baseX.get() > 100) {
      baseX.set(baseX.get() - 200);
    } else if (baseX.get() < -100) {
      baseX.set(baseX.get() + 200);
    }
  });

  return (
    <div className="relative overflow-hidden w-full py-2">
      <motion.div className="flex whitespace-nowrap" style={{ x }}>
        <span
          className="md:text-4xl lg:text-5xl xl:text-5xl text-3xl font-extrabold text-blue-500 mx-5"
          style={{ lineHeight: "1.3em" }}
        >
          {children}
        </span>
        <span
          className="md:text-4xl lg:text-5xl xl:text-5xl text-3xl font-extrabold text-blue-500 mx-5"
          style={{ lineHeight: "1.3em" }}
        >
          {children}
        </span>
      </motion.div>
    </div>
  );
}

export default function AnimationText({ text1, text2 }) {
  return (
    <section className="min-h-auto bg-gray-100 flex flex-col justify-center items-center text-center text-gray-900 overflow-hidden">
      <ParallaxText baseVelocity={-10}>{text1}</ParallaxText>
      <ParallaxText baseVelocity={10}>{text2}</ParallaxText>
    </section>
  );
}
