import React, { useEffect, useState } from "react";
import { FcCheckmark } from "react-icons/fc";
import video from "../../../assets/images/eductionvideo.mp4";
import CategoriesBanner from "../../../components/CategoriesBanner";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import RenderRazorpay from "../../utility/Render-Razor-pay";
import config from "../../../config/config.json";
import { useNavigate, useParams } from "react-router-dom";

const CourseDetail = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState(null);
  // const [course, setcourse] = useState(courseDetails)

  const { API_BASE_URL, RAZOR_PAY_API_KEY, RAZOR_PAY_API_SECRET } = config;
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const assignCourse = async (course) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/login-users-pf-ds-bd");
    }

    const assignCourses = {IsActive:true,userId:user.uuid, Courseid:Number(id),CoursePrice:"0" }

    try{
      await fetch(`${API_BASE_URL}/Courses/CreateAssignedCourses`,{
        method:'POST',
        headers :{'Content-Type':'application/json'},
        body : JSON.stringify({AssignedCourses : [assignCourses]})
      }).then(()=> alert("Enrollment Successfull")).then(()=>  navigate("/user-profile#"))
        // console.log("data ",data)
       
      
      }
    catch(error){
      console.error("API Error",error.Error);
    }
  };

  useEffect(() => {
    const getCourseDetail = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/Courses/GetCourseDetailsbyid`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ Id: parseInt(id) }), // Assuming your API expects courseId in the body
          }
        );
        const data = await response.json();
        setCourseDetails(data);
        console.log("data", data);
      } catch (error) {
        console.error("api error", error);
      }
    };
    getCourseDetail();
  }, []);

  return (
    <>
      {/* <CategoriesBanner /> */}
      <Navbar />
      {courseDetails?.map((course, index) => (
        <div key={index} className="bg-gray-50 text-gray-900 py-8">
          <div className="container mx-auto px-4 lg:px-8 max-w-8xl">
            <nav className="text-sm text-gray-500 mb-4">
              <span className="hover:underline cursor-pointer">
                Development
              </span>{" "}
              &gt;{" "}
              <span className="hover:underline cursor-pointer">
                Web Development
              </span>{" "}
              &gt; <span className="text-gray-900">{course.courseName}</span>
            </nav>

            <div className="lg:flex lg:justify-between bg-gray-800 text-gray-100 md:p-4 lg:p-6 xl:p-8 p-4 rounded-lg">
              <div className="lg:w-2/3">
                <h1 className="text-3xl md:text-4xl font-bold mb-4">
                  {course.courseName}
                </h1>
                <p className="text-lg mb-6">{course.courseDescription}</p>

                <div className="flex items-center mb-6">
                  <span className="text-yellow-500 text-xl font-bold mr-2">
                    {course.totalRating}
                  </span>
                  <span className="text-gray-200">/204 Ratings</span>
                </div>

                <div className="text-gray-200 mb-2">
                  Created by{" "}
                  <span className="text-gray-300 font-semibold">
                    Colt Steele
                  </span>
                </div>
                <div className="text-gray-200 mb-8">
                  Last updated 6/2024 • English [Auto], Arabic [Auto], 14 more
                </div>
              </div>

              <div className="lg:w-1/3 bg-white p-4 rounded-lg shadow-lg lg:ml-8">
                <div className="relative mb-4">
                  <video className="rounded-lg w-full h-auto" controls>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <button
                  onClick={() => assignCourse(courseDetails)}
                  className="bg-purple-600 w-full py-3 rounded-lg text-white font-semibold hover:bg-purple-700 transition"
                >
                  Enroll Now
                </button>
                {/* <p className="text-gray-800 text-center mt-2">
                  Starting at ₹850 per month. Cancel anytime
                </p> */}
              </div>
            </div>

            <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">What you'll learn</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700">
                <ul className="list-disc list-inside">
                  <li>
                    The ins and outs of HTML5, CSS3, and Modern JavaScript for
                    2021
                  </li>
                  <li>Create responsive, accessible, and beautiful layouts</li>
                </ul>
                <ul className="list-disc list-inside">
                  <li>
                    Make REAL web applications using cutting-edge technologies
                  </li>
                  <li>
                    Recognize and prevent common security exploits like
                    SQL-Injection & XSS
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {displayRazorpay && (
            <RenderRazorpay
              amount="300"
              currency="INR"
              orderId="order_OPJmdgzvtiWY1a"
              keyId={RAZOR_PAY_API_KEY}
              keySecret={RAZOR_PAY_API_SECRET}
            />
          )}
        </div>
      ))}
      <Footer />
    </>
  );
};

export default CourseDetail;
