import React from 'react'
import CategoriesBanner from '../components/CategoriesBanner'
import Navbar from '../components/Navbar'
import ProfileHero from '../components/Profile/ProfileHero'
import Footer from '../components/Footer'

function ProfilePage() {
    return (
        <div>
            {/* <CategoriesBanner /> */}
            <Navbar />
            <ProfileHero />
            <Footer />
        </div>
    )
}

export default ProfilePage