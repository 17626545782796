import React from 'react';

const CourseDetailsModal = ({ course, position }) => (
    <div
        className="absolute w-80 bg-[#222] shadow-lg rounded-lg p-4 transition-opacity ease-in-out md:block lg:block xl:block hidden duration-400"
        style={{ top: position.top, left: position.left, opacity: course ? 1 : 0 }}
    >
        <h3 className="text-xl text-white font-bold mb-2">{course.courseName}</h3>
        <p className="text-gray-100 mb-2 italic">{course.createdBy}</p>
        <p className="text-gray-200 text-sm mb-2">{course.courseDescription}</p>
        <p className="text-white font-semibold bg-blue-500 w-fit px-4 py-1 rounded-md">₹{course.pricing}</p>
    </div>
);

export default CourseDetailsModal;
