import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config/config.json";
import CartButton from '../CartButton';

function Instructor() {
    const { API_BASE_URL } = config;
    const [instructors, setInstructors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.post(`${API_BASE_URL}/User/GetStudentDetailsByID`, {
            ID: -1,
            RoleId: 2,
            IsInstructor: 1
        })
            .then(response => {
                const data = response.data;
                const formattedData = data.map(item => ({
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    CityName: item.CityName,
                    Photo: item.Photo,
                    AboutUs: item.AboutUs
                }));
                setInstructors(formattedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError('Failed to load instructors. Please try again later.');
            })
            .finally(() => setLoading(false));
    }, [API_BASE_URL]);

    return (
        <section className='py-6'>
            {loading ? (
                <div className="flex justify-center items-center min-h-screen">
                    <p>Loading...</p>
                </div>
            ) : (
                <div className='min-h-screen'>
                    {/* <form className="flex items-center max-w-lg mx-auto mt-10">
                        <label htmlFor="voice-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" id="voice-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full ps-10 p-2.5" placeholder="Search Instructors.." required />
                        </div>
                        <button type="submit" className="inline-flex items-center py-2 px-5 ms-2 text-md font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                            Search
                        </button>
                    </form> */}

                    <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-6">
                        <hr className='border-b border-gray-300/70 mb-5' />
                        {error && (
                            <div className="text-red-500 text-center mb-4">{error}</div>
                        )}
                        <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                            {instructors.map((instructor, index) => (
                                <div key={index} className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
                                    <img
                                        src={`${API_BASE_URL}/${instructor.Photo}`}
                                        className="object-cover w-full h-64"
                                        alt={`${instructor.FirstName} ${instructor.LastName}`}
                                    />
                                    <div className="p-5 border border-t-0">
                                        <h2 className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700">
                                            {instructor.FirstName} {instructor.LastName}
                                        </h2>
                                        <p className="mb-2 text-gray-700 line-clamp-2">{instructor.AboutUs}</p>
                                        <p className="text-gray-500">City: {instructor.CityName}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {/* <CartButton /> */}
        </section>
    );
}

export default Instructor;
