import React from 'react'
import CategoriesBanner from '../components/CategoriesBanner'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Instructor from '../components/Instructor/Instructor'

function InstructorPage() {
    return (
        <div>
            {/* <CategoriesBanner /> */}
            <Navbar />
            <Instructor />
            <Footer />
        </div>
    )
}

export default InstructorPage