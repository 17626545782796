import React, { useState } from 'react'
import { Link, json, useLocation } from 'react-router-dom';
import { Input } from '../../Input'
import { FormProvider, useForm } from 'react-hook-form'
import { BsFillCheckSquareFill } from 'react-icons/bs'
import OtpInput from './otp-Input'
import ChangePassword from "./ChangePassword";
import edumitramLogo from '../../../assets/eduMitram.png'
import config from '../../../config/config.json';






import {
  ToemailSend_validation
} from '../../../utils/inputValidations'


export const ForgotPassword = () => {
  const { API_BASE_URL } = config;



  const methods = useForm()
  const [success, setSuccess] = useState(false);
  const [componentFalg, setcomponetFlag] = useState(true)
  const [errorMessage, setErrorMessage] = useState('');
  const [toEmail, setToEmail] = useState(null);
  const [otpSucess,setOtpSucess] = useState(false);
  const [otpErrorMesaage,setOtpErrorMesaage] = useState('')
  const [changepsswordflag,setchangepsswordflag]=useState(true);


  const onSubmit = methods.handleSubmit(async (data) => {
    console.log("email data", data);

    const email = data.ToEmail;
    setToEmail(email);

    console.log("setToEmail", email);

    try {
      const response = await fetch(`${API_BASE_URL}/User/Send`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      if (response.ok) {
        console.log("if block")
        let data = response.text();
        console.log("data", data)
        methods.reset();
        setSuccess(true);
        setcomponetFlag(false);
        setErrorMessage(''); // Clear any previous error message



      }
      else {
        setSuccess(false);

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          let errorData = await response.json();
          setErrorMessage(errorData.message || 'Something went wrong');
        } else {
          let errorText = await response.text();
          setErrorMessage(errorText);
        }
      }
    } catch (error) {
      console.error("api error", error)
      setErrorMessage('Network error. Please try again later.');
    }
  })
  const onOtpSubmit = async (Otp) => {
    const OtpInt = parseInt(Otp, 10);

    console.log("Login Successful", OtpInt);
    try {
      const response = await fetch(`${API_BASE_URL}/User/OtpVerified`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({ Otp: OtpInt, ToEmail: toEmail })
      })
      if (response.ok) {
        console.log("if block")
        let data = response.text();
        console.log("data", data)
        methods.reset();
        setOtpSucess(true);
        setErrorMessage('');
         setchangepsswordflag(false);



      }
      else {
        // setchangepsswordflag(false);

        setOtpSucess(false);

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          let errorData = await response.json();
          console.log("errorData", errorData)
          setErrorMessage(errorData.message || 'Something went wrong');
        } else {

          let errorText = await response.text();
          console.log("errorText", errorText)

          setOtpErrorMesaage(errorText);
        }
      }
    } catch (error) {
      console.error("api error", error)
      setErrorMessage('Network error. Please try again later.');
    }


  };
  const template = {
    forgotheading: "Forgot your password",
    otpheading: 'Otp Vefictaion',
    psswordheading:"Change Password",
    password :'',

    Pforgot: "Please Enter the Email Address you'd like your password verification code send to email address",
    Potp: "Please enter 4-digit verification code, we sent via Email Address",
    // resetpasswordbtn: "Reset Password",


  }




  return (
    <>
      <div className='min-h-screen w-full bg-slate-700 flex justify-center items-center'>

        <div className=' w-1/3 bg-white px-16 pb-10 pt-4   rounded-md '>
        <Link to="/">
        <img src={edumitramLogo} alt='edumitramLogo' className='w-[80%] h-20 mb-6 mx-10' />

        </Link>

          <h1 className='text-2xl mb-6 font-bold'>{componentFalg ? template?.forgotheading : !changepsswordflag? template.psswordheading : template?.otpheading}</h1>

          <p className='text-lg mb-3'>{componentFalg ? template?.Pforgot : !changepsswordflag? template.password : template?.Potp} </p>
          {/* <p className='mb-4 text-lg'>your password verification code send to email</p> */}

          {componentFalg  && changepsswordflag ?( 
            <FormProvider {...methods}>


              <form onSubmit={e => e.preventDefault()}
                noValidate
                autoComplete="off"
                className="contadiner">
                <Input {...ToemailSend_validation} />

                <div className="mt-5">
                  {success && (
                    <p className="font-semibold text-green-500 mb-5 flex items-center gap-1">
                      <BsFillCheckSquareFill /> Otp send successfully
                    </p>
                  )}
                  {errorMessage && (
                    <p className="error text-red-600"> {errorMessage} </p>
                  )}

                  <button onClick={onSubmit} className='w-full bg-blue-600 text-white font-medium text-sm rounded-md border-transparent py-3 px-5 mt-4 hover:bg-blue-700'>Reset Password</button>
                </div>
              </form>
            </FormProvider>)

          : changepsswordflag ? (
           
            <><div className='px-8'>
                <OtpInput length={4} onOtpSubmit={onOtpSubmit} />
              </div><div className="mt-5">
                  {otpSucess && (
                    <p className="font-semibold text-green-500 mb-5 flex items-center gap-1">
                      <BsFillCheckSquareFill /> Otp Verified
                    </p>
                  )}
                  {otpErrorMesaage && (
                    <p className="error text-red-600 text-center font-semibold text-xl"> {otpErrorMesaage} </p>
                  )}
                </div></>
          ):
          <ChangePassword  ToEmail= {toEmail}/>

          
        
        }


          

          <h1 className='text-center mt-4'> <Link to={`/login-user`} className=' text-blue-500 font-semibold text-[18px]'> Back To Login  </Link> </h1>

        </div>

      </div>





    </>



  )
}

export default ForgotPassword;
