import React from 'react';
import image from "../../assets/business.png";
import bgimage from "../../assets/worldmap.png"
import CartButton from '../CartButton';
import {Link} from 'react-router-dom'
function BusinessHero() {
    return (
        <section>
            <div className="relative bg-contain md:bg-center lg:bg-center xl:bg-center bg-no-repeat" style={{ backgroundImage: `url(${bgimage})` }}>

                <div class="flex px-6 md:px-20 items-center justify-center bg-hero md:h-screen overflow-hidden">
                    <div class="flex flex-col gap-6 md:flex-row items-center max-w-8xl">
                        <div class="w-full md:w-1/2 lg:pr-32">
                            <h2 class="text-3xl lg:text-5xl text-center md:text-left text-blue-900 leading-tight font-bold lg:-mt-8 mt-6">Turn Talent into Your Strategic Advantage</h2>
                            <ul class="list-none pl-0 text-gray-700 font-medium tracking-wider leading-relaxed mt-10">
                                <li class="flex items-center mb-4 md:text-lg lg:text-lg xl:text-lg text-md">
                                    <svg class="w-5 h-5 text-green-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z" clip-rule="evenodd" />
                                    </svg>
                                    Enhance Essential Skills with Trusted Content
                                </li>
                                <li class="flex items-center mb-4 md:text-lg lg:text-lg xl:text-lg text-md">
                                    <svg class="w-5 h-5 text-green-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z" clip-rule="evenodd" />
                                    </svg>
                                    Reduce Training Costs Without Compromising Quality
                                </li>
                                <li class="flex items-center mb-4 md:text-lg lg:text-lg xl:text-lg text-md">
                                    <svg class="w-5 h-5 text-green-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z" clip-rule="evenodd" />
                                    </svg>
                                    Monitor and Assess Skills to Prove ROI
                                </li>
                            </ul>
                            <div class="mt-10 flex flex-col sm:flex-row justify-center md:justify-start">
                                <button class="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-blue-500 text-white border-2 border-blue-500"><Link to={'/login-users-pf-ds-bd'}>Login Now</Link></button>
                                {/* <button class="w-full mt-4 sm:mt-0 sm:ml-4 sm:w-40 px-2 py-3 rounded font-semibold text-md bg-white text-blue-500 border border-gray-400/70 shadow-md shadow-gray-400/80">Schedule a Demo</button> */}
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 flex justify-center md:justify-end">
                            <img src={image} alt='individual-homepage' />
                        </div>
                    </div>
                </div>
            </div>

            {/* <CartButton /> */}
        </section>
    )
}

export default BusinessHero