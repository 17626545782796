/*-------------------------------------------------------------------
|  🐼 Input Validators 
|
|  🐯 Purpose: THIS FILE CONTAINS ALL THE VALIDATORS OBJECTS
|
|  🐸 Returns:  -
*-------------------------------------------------------------------*/


export const first_name_validation = {
    name: 'FirstName',
    label: 'First Name',
    type: 'text',
    id: ' First Name',
    placeholder: 'write your first name ...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
      maxLength: {
        value: 30,
        message: '30 characters max',
      },
    },
  }

  export const username_validation = {
    name: 'Username',
    label: 'User name',
    type: 'email',
    id: 'email',
    placeholder: 'Write  user name ...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'not valid email',
      },
    },
  }

  export const last_name_validation = {
    name: 'LastName',
    label: 'Last Name',
    type: 'text',
    id: ' Last Name',
    placeholder: 'write your last name ...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
      maxLength: {
        value: 30,
        message: '30 characters max',
      },
    },
  }
  
  // export const desc_validation = {
  //   name: 'description',
  //   label: 'description',
  //   multiline: true,
  //   id: 'description',
  //   placeholder: 'write description ...',
  //   validation: {
  //     required: {
  //       value: true,
  //       message: 'required',
  //     },
  //     maxLength: {
  //       value: 200,
  //       message: '200 characters max',
  //     },
  //   },
  // }
  
  export const password_validation = {
    name: 'Password',
    label: 'password',
    type: 'password',
    id: 'password',
    placeholder: 'password ...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
      minLength: {
        value: 6,
        message: 'min 6 characters',
      },
      pattern: {
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        message: 'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character',
      },
    },
  }
  export const confirm_password_validation = {
    name: 'ConfirmPassword',
    label: 'Confirm Password',
    type: 'password',
    id: 'password',
    placeholder: 'confirm password ...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
      minLength: {
        value: 6,
        message: 'min 6 characters',
      },
      pattern: {
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        message: 'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character',
      },
      
    },
  }
  
  
  export const num_validation = {
    name: 'MobileNumber',
    label: 'Mobile Number',
    type: 'number',
    id: 'num',
    placeholder: 'write a mobile number...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
    },
  }
  
  export const email_validation = {
    name: 'Email',
    label: 'email address',
    type: 'email',
    id: 'email',
    placeholder: 'write a email address ...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'not valid email',
      },
    },
  }

  export const ToemailSend_validation = {
    name: 'ToEmail',
    label: 'email address',
    type: 'email',
    id: 'email',
    placeholder: 'write a email address...',
    validation: {
      required: {
        value: true,
        message: 'required',
      },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'not valid email',
      },
    },

    
  }

 export const role_options = {
    name: 'role',
    label: 'Role',
    id: 'role',
    placeholder: 'Select your role',

    validation: { required: 'required' },
    options: [
      { value: 2, label: 'Teacher' },
      { value: 3, label: 'Student' },
    
    ],
  }