import React from 'react'
import SchoolHero from '../components/School/SchoolHero'
import CategoriesBanner from '../components/CategoriesBanner'
import Navbar from '../components/Navbar'
import SchoolMission from '../components/School/SchoolMission'

function SchoolPage() {
    return (
        <div>
            {/* <CategoriesBanner /> */}
            <Navbar />
            <SchoolHero />
            <SchoolMission />
        </div>
    )
}

export default SchoolPage